@use "sass:math";

@mixin m-x-list {
	@for $i from 1 through 50 {
		.mv-#{$i} {
			margin-top: #{math.div($i,10)}rem !important;
			margin-bottom: #{math.div($i,10)}rem !important;
		}
		.mh-#{$i} {
			margin-left: #{math.div($i,10)}rem !important;
			margin-right: #{math.div($i,10)}rem !important;
		}
		.mt-#{$i} {
			margin-top: #{math.div($i,10)}rem !important;
		}
		.mb-#{$i} {
			margin-bottom: #{math.div($i,10)}rem !important;
		}
		.ml-#{$i} {
			margin-left: #{math.div($i,10)}rem !important;
		}
		.mr-#{$i} {
			margin-right: #{math.div($i,10)}rem !important;
		}
		.pt-#{$i} {
			padding-top: #{math.div($i,10)}rem !important;
		}
		.pb-#{$i} {
			padding-bottom: #{math.div($i,10)}rem !important;
		}
	}
}
