$primary: #037fb0;
$primary-hover: #056d96;
$primary-active: #045e81;
$secondary-hover: #f1fafe;
$secondary-active: #dcf2fe;
$pure-white: #fff;
$input-light-bg: rgba(255, 255, 255, 0.9);
$input-text-color: #3c4043;
$multi-lang-input-dot: #FD2B16;
$input-text-light-color: #9d9fa1;
$input-border-color: #9d9fa1;
$shadow: #e6e5e5;
$edit-icon: #aeb0b2;
$names-thumbnail-bg: #f3f3f3;
$table-border-color: #f3f3f3;
$display-more-button-color: #007FAE;
$dropdown-hover-bg: #f3f3f3;
$disabled-input-bg: #f3f3f3;
$td-border-color: #f3f3f3;
$radio-checked-color: #ffa200;
$radio-disabled-bg: #f3f3f3;
$checkbox-checked-color: $primary;
$sidebar-bg: $primary;
$sidebar-hover-bg: #056d96;
$sidebar-dropdown-click-bg: #d9d9d9;
$create-buttons-bg: #ffe7c4;
$hover-list-text: #ffa200;
$dashboard-version-color: #ffa200;
$multi-selected-border: #51667b;
$select-arrow: #00818d;
$select-small-arrow: #3b4043;
$select-small-border: #51667b;
$breadcrumb-active-color: #3c4043;
$breadcrumb-color: #3c4043;
$expand-icon: #3c4043;
$sidebar-icons: #fff;
$multi-selected-remove-color: #3c4043;
$text-color: #3c4043;
$user-thumbnail-border: #3c4043;
$nav-icon-active-color: #3c4043;
$text-light-color: #aeb0b2;
$hover-list: #e6e5e5;
$tree-nav-bg: #f9f9f9;
$search-activity-bg: #f9f9f9;
$dashed-border: #c0c2c3;
$button-dark-bg: #fff;
$button-dark-color: #3b4043;
$button-dark-border-color: #51667b;
$button-disabled-border-color: rgba(112, 112, 112, 0.3);
$button-disabled-color: rgba(112, 112, 112, 0.3);
$nav-icon-color: #6E6E6E;
$nav-text-color: #3c4043;
$ng-select-border-color: #919191;
$ng-select-placeholder-color: #6E6E6E;
$ng-select-disabled-placeholder-color: #3C4043;
$order-item-table-border: #919191;
$tabs_border: #919191;
$tabs_link_color: #037FB0;
$tabs_link_active_color: #3C4043;
$ng-select-arrow-border: #f3f3f3;
$tab-bg: #f9f9f9;
$auth-text: #fff;
$modal-border: #707070;
$hr: #707070;
$light-hr: #F3F3F3;
$required-symbol: #E00000;
$error-primary: #a7000d;
$error-background: #fff5f5;
$error-border: #d29195;
$alert-danger-background: #f9f1f3;
$alert-danger-border: #A7000D;
$alert-success-background: #e5f7f4;
$alert-warning-background: #fff5e9;
$alert-success-border: #289382;
$alert-warning-border: #E07800;
$info-modal-bg: #FFF5E9;
$info-modal-color: #FF9C2A;
$info-modal-border-color: #FFCD94;
$border-color-move: #919191