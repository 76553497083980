@import 'variables';
@import 'mixins';
// Your variable overrides
//$body-bg: #999;
//$body-color: #111;
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import '@ng-select/ng-select/themes/default.theme.css';
// @import '~@ng-select/ng-select/scss/material.theme';

html,
body {
	height: 100%;
	font-size: 62.5%; // 10px
	color: $text-color;
}

body {
	font-family: 'Open Sans', sans-serif;
	font-size: 1.3rem; // 13px
}
strong {
	font-weight: 600;
}

@font-face {
	font-family: 'admin-icons';
	src: url('/assets/fonts/admin-icons.eot?ajn9p5');
	src: url('/assets/fonts/admin-icons.eot?ajn9p5#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/admin-icons.ttf?ajn9p5') format('truetype'),
		url('/assets/fonts/admin-icons.woff?ajn9p5') format('woff'),
		url('/assets/fonts/admin-icons.svg?ajn9p5#admin-icons') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'admin-icons', sans-serif !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@include m-x-list;

ul {
	list-style: none;
}

.icon-expand:before {
	content: '\e90e';
}
.icon-select:before {
	content: '\e90f';
}
.icon-edit:before {
	content: '\e910';
}
.icon-check:before {
	content: '\e906';
}
.icon-close:before {
	content: '\e907';
}
.icon-upload:before {
	content: '\e908';
}
.icon-sort:before {
	content: '\e901';
}

h1,
.h1 {
	font-size: 2.5rem;
	font-weight: normal;
}

h2,
.h2,
legend,
.legend {
	font-size: 1.8rem;
	line-height: 2.4rem;
	font-weight: normal;
}

h3,
.h3 {
	font-size: 1.5rem;
	font-weight: normal;
}

.bold {
	font-weight: bold;
}

.semibold {
	font-weight: 700;
}

.textarea-input {
	label {
		font-weight: 600;
	}
}

.ellipsis {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// turn off arrows for number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

input[type='text'],
textarea,
input[type='number'],
select,
input[type='email'],
input[type='password'] {
	padding: 0 1.5rem;
	font-size: 1.3rem !important;
	height: 4rem;
	-webkit-border-radius: 0.2rem !important;
	-moz-border-radius: 0.2rem !important;
	border-radius: 0.2rem !important;
	&.light {
		border: none;
		background-color: $input-light-bg;
		color: $input-text-light-color;
	}

	&.dark {
		color: $input-text-color;
		border: 1px solid $input-border-color;
		&.tooltip-first{
			background-image: none;
		}
	}

	&.is-invalid {
		background-color: $error-background;
		border: 1px solid $error-primary !important;
	}

	& + .invalid-feedback {
		margin-top: 0.8rem;
		font-size: 1.3rem;
	}

	&:disabled {
		border: none;
		background-color: $disabled-input-bg !important;
		opacity: 1;

		& + .icon-select {
			color: $select-small-arrow;
		}

		& + .input-group-append > span {
			border: none;
		}
	}

	&:focus {
		border: 1px solid $primary;
		outline: none;
	}
}
textarea {
	height: auto;
}

.select {
	position: relative;
	select {
		padding-right: 3.3rem;
		display: block;
		width: 100%;
		-webkit-appearance: none;
		-moz-appearance: none;
		text-indent: 1px;
		background-color: transparent;
	}
	> span {
		position: absolute;
		right: 1.6rem;
		top: 0;
		bottom: 0;
		font-size: 0.6rem;
		color: $select-arrow;
		z-index: -1;
		.modal-body & {
			z-index: 0;
		}
		&:after {
			content: '';
			display: inline-block;
			vertical-align: middle;
			height: 100%;
		}
	}
	&.small {
		> select {
			height: 2.5rem;
			border-color: $select-small-border;
		}
		> span {
			color: $select-small-arrow;
		}
	}
}

.custom-checkbox {
	position: relative;
	padding-left: 2.9rem;
	> label {
		cursor: pointer;
		.check {
			position: absolute;
			top: 0;
			left: 0;
			width: 2rem;
			height: 2rem;
			-webkit-border-radius: 0.2rem;
			-moz-border-radius: 0.2rem;
			border-radius: 0.2rem;
			border: 1px solid $input-border-color;
			background-color: $pure-white;
			text-align: center;
			&:after {
				@extend [class^='icon-'];
				content: '\e906';
				display: none;
				font-size: 1rem;
				color: $pure-white;
			}
		}
	}
	> input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		left: 0;
		height: 2rem;
		width: 2rem;
		&:disabled + label {
			opacity: 0.6;
		}
		&:checked + label .check {
			background-color: $checkbox-checked-color;
			border: none;
			&:after {
				display: inline-block;
			}
		}

		&:focus {
			& + label .check {
				outline: 5px auto -webkit-focus-ring-color
			}
		}
	}
}

.input-container {
	&.textarea {
		.max-length {
			font-weight: 600;
		}
	}
	&.multi-select .r-multi-select {
		padding: 0.6rem;
		flex-wrap: wrap;
		font-size: 1.3rem;
		min-height: 3.8rem;
		-webkit-border-radius: 0.2rem;
		-moz-border-radius: 0.2rem;
		border-radius: 0.2rem;
		color: $input-text-color;
		background-color: $pure-white;
		border: 1px solid $input-border-color;
		position: relative;
		ul.options-selected {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				&:after {
					content: '';
					height: 100%;
					display: inline-block;
					vertical-align: middle;
				}
				> span {
					vertical-align: middle;
				}
				margin-right: 1rem;
				height: 2.6rem;
				display: inline-block;
				padding: 0 0.9rem;
				-webkit-border-radius: 0.2rem;
				-moz-border-radius: 0.2rem;
				border-radius: 0.2rem;
				color: $primary;
				border: 1px solid $primary;
				padding: 2px 10px;

				&:hover,
				&:focus {
					background-color: $secondary-hover;
				}

				&:active {
					background-color: $secondary-active;
				}

				button {
					vertical-align: middle;
					margin-left: 1.2rem;
					padding: 0;
					font-size: 1rem;
					background-color: transparent;
					color: $primary;
					border: none;
				}
			}
		}
		input {
			border: none;
			height: 2.6rem;
			max-width: 100%;
			flex-grow: 1;
			display: block;
		}
		ul.options-container {
			background-color: $pure-white;
			padding: 0;
			list-style: none;
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
			z-index: 1;
		}
	}
	&.radio {
		label,
		div.label {
			position: relative;
			padding-left: 3.5rem;
			margin-right: 3rem;
			cursor: pointer;
			&.d-block {
				padding-left: 0;
				margin-right: 0;
			}
			.check {
				position: absolute;
				left: 0;
				width: 2rem;
				height: 2rem;
				-webkit-border-radius: 1rem;
				-moz-border-radius: 1rem;
				border-radius: 1rem;
				border: 1px solid $input-border-color;
				background-color: $pure-white;
				transition: all 0.3s cubic-bezier(0.455, 0.03, 0.215, 1.33) 0s;
				&:after {
					content: '';
					display: none;
					position: absolute;
					top: 50%;
					right: 50%;
					margin-top: -2.5px;
					margin-right: -2.5px;
					width: 5px;
					height: 5px;
					-webkit-border-radius: 50%;
					-moz-border-radius: 50%;
					border-radius: 50%;
				}
			}
			input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
				height: 0;
				width: 0;
				&:checked + .check {
					border-width: 7px;
					background: $pure-white;
					border-color: $primary;
					&:after {
						display: block;
					}
				}
				&:focus + .check {
					outline: 5px auto -webkit-focus-ring-color;
				}
			}
			&.disabled {
				.check {
					background-color: $radio-disabled-bg;
				}
				opacity: 0.5;
			}
		}
	}
	&.picture {
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}
		.upload-container {
			margin-top: 0.5rem;
			cursor: pointer;
			border: 1px dashed $dashed-border;
			width: 14.2rem;
			height: 12.6rem;
			position: relative;
			display: block;
			&:after {
				@extend [class^='icon-'];
				content: '\e908';
				color: $text-light-color;
				font-size: 2.4rem;
			}
			img {
				max-width: 100%;
				max-height: 100%;
			}
			.icon-edit {
				bottom: 0.9rem;
				z-index: 100;
				color: $edit-icon;
				position: absolute;
				right: 0.9rem;
			}
			&.success {
				&:after {
					display: none;
				}
			}
		}
	}
}

a {
	&.underline {
		text-decoration: underline;
	}
	&.dark {
		color: $text-color;
	}
	&.light {
		color: $pure-white;
	}
	&.r-button {
		display: inline-block;
		cursor: pointer;
		line-height: 4rem;
		text-align: center;
		&:hover {
			text-decoration: none;
		}
	}

	&.secondary {
		color: $primary !important;
		border-color: $primary !important;
		&:hover,
		&:focus {
			background-color: $secondary-hover !important;
		}
		&:active {
			background-color: $secondary-active !important;
		}
	}

	&.create {
		color: $primary !important;
		border-color: $primary !important;
		line-height: 2.5rem !important;
		height: unset !important;
		&:hover {
			background-color: $secondary-hover !important;
		}
		&:active {
			background-color: $secondary-active !important;
		}
	}
}

button {
	&.primary:not(.outline){
		&:hover,
		&:focus {
			background-color: $primary-hover !important;
		}
		&:active {
			background-color: $primary-active !important;
		}
	}


	&.secondary {
		border-color: $primary;
		color: $primary;
		&:hover,
		&:focus {
			background-color: $secondary-hover !important;
		}
		&:active {
			background-color: $secondary-active !important;
		}
	}

	&.create {
		border-color: $primary;
		color: $primary;
		line-height: 2.5rem;
		height: unset !important;

		&:hover,
		&:focus {
			background-color: $secondary-hover !important;
		}
		&:active {
			background-color: $secondary-active !important;
		}
	}

	&.danger {
		border-color: $error-primary;
		color: $error-primary;
		&:hover,
		&:focus {
			background-color: $error-background !important;
		}
		&:active {
			background-color: $error-background !important;
		}
	}
}

.skip-navigation {
	&:focus {
		margin: 0 auto;
		width: 15rem;
		height: auto;
		overflow: visible;
		clip: auto;
		white-space: normal;
		background: #fff;
		color: #0075be;
		text-align: center;
	}
}

.expand-button {
	margin-left: 1.2rem;
	padding: 0;
	border: none;
	font-size: 1.1rem;
	background-color: transparent;
	color: $expand-icon;
	-webkit-transition: transform 0.35s;
	-moz-transition: transform 0.35s;
	transition: transform 0.35s;
	&.active {
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		transform: rotate(180deg);
	}
}

.modal-backdrop {
	&.show {
	}
}

.dp-hide {
	position: absolute;
	bottom: 0;
	height: 0;
	> input {
		height: 0;
		border: none !important;
		padding: 0 !important;
	}
}

.custom-datepicker + .dropdown-menu {
	.custom-day {
		text-align: center;
		padding: 0.3rem 0.4rem;
		display: inline-block;
		height: 3.2rem;
		width: 3.2rem;
	}
	.custom-day.focused {
		background-color: #e6e6e6;
	}
	.custom-day.range,
	.custom-day:hover {
		background-color: rgb(2, 117, 216);
		color: white;
	}
	.custom-day.faded {
		background-color: rgba(2, 117, 216, 0.5);
	}

	border-radius: 0.4rem !important;
	font-size: 1.6rem;
	.ngb-dp-week-number,
	.ngb-dp-weekday {
		line-height: 3.2rem;
	}
	.ngb-dp-header {
		border-radius: 0.4rem 0.4rem 0 0 !important;
		padding-top: 0.4rem !important;
	}
	[ngbDatepickerDayView] {
		width: 3.2rem;
		height: 3.2rem;
		line-height: 3.2rem;
		border-radius: 0.4rem;
	}
	.ngb-dp-arrow {
		width: 3.2rem !important;
		height: 3.2rem !important;
	}
	.ngb-dp-arrow-btn {
		padding: 0 0.4rem !important;
		margin: 0 0.8rem !important;
	}
	.ngb-dp-navigation-select {
		flex: 1 1 14.4rem !important;
	}
	.ngb-dp-day,
	.ngb-dp-week-number,
	.ngb-dp-weekday {
		width: 3.2rem !important;
		height: 3.2rem !important;
	}
	.ngb-dp-month:first-child .ngb-dp-week {
		padding-left: 0.4rem !important;
	}
	.ngb-dp-month:last-child .ngb-dp-week {
		padding-right: 0.4rem !important;
	}

	.ngb-dp-navigation-select > .custom-select {
		padding: 0 0.8rem !important;
		font-size: 1.4rem !important;
		height: 3rem !important;
	}
	.ngb-dp-week {
		border-radius: 0.4rem !important;
	}
}

.modal-content {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	border: 1px solid $modal-border;
	.modal-header {
		padding: 7rem 4rem 4rem;
		text-align: center;
		border: none;
		&.address-verification{
			padding: 7rem 4rem 0rem;
		}
	}
	.modal-body {
		padding: 0 4rem;
	}
	.modal-footer {
		padding-top: 6rem;
		padding-bottom: 6.5rem;
		border: none;
	}
	.close {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		padding: 1.8rem;
		opacity: inherit;

		.icon-close {
			font-size: 1rem;
			color: #3c4043;
		}
	}
}
// fixed ng-select text off
.ng-select .ng-select-container .ng-value-container .ng-placeholder{
	color: #3c4043;
}
.ng-input input {
	height: unset;
}

.ng-select.custom {
	&:focus,
	&.ng-select-focused {
		outline: 1px solid $primary;
	}
	& .ng-select-container {
		min-height: 4rem;
		border-color: $ng-select-border-color;
		border-radius: 1px;
		width: 100%;

		&:hover {
			border: 1px solid $primary;
		}

		.ng-value-container {
			input {
				//color: transparent;
			}
			.ng-placeholder {
				top: unset !important;
				color: $ng-select-placeholder-color !important;
				opacity: 1;
			}

			.ng-value {
				font-size: 13px;
				background-color: unset;
				align-items: center;
				display: inline-flex;

				.ng-value-icon.right {
					border-left: none;
					font-size: 18px;
					line-height: 18px;
					display: inline-block;
					padding: 0;
					&:hover {
						background-color: unset;
					}
				}
			}
		}
	}

	&.is-invalid .ng-select-container {
		background-color: #fff5f5;
		border: 1px solid #a7000d !important;
	}

	&.ng-select-multiple,
	&.ng-select-single {
		& .ng-select-container {
			.ng-value-container {
				.ng-value {
					color: $primary;
					border: 1px solid $primary;
					padding: 2px 10px;

					&:hover,
					&:focus {
						background-color: $secondary-hover;
					}

					&:active {
						background-color: $secondary-active;
					}
				}
			}
		}
	}

	&.ng-select-single {
		& .ng-select-container {
			.ng-value-container {
				.ng-value {
					color: $text-color;
					border: none;
					padding: 0px 5px;

					&:hover,
					&:focus {
						background-color: unset;
					}

					&:active {
						background-color: unset;
					}
				}
			}
		}
	}

	& .ng-arrow-wrapper {
		width: unset;
		display: flex;
		position: absolute;
		padding-right: 0;
		top: 17px;
		right: 12px;
		-webkit-transition: transform 0.35s;
		-moz-transition: transform 0.35s;
		transition: transform 0.35s;

		& .ng-arrow {
			display: none;
		}
	}

	& span.ng-arrow-wrapper:before {
		font: normal normal normal 14px/1 'admin-icons', sans-serif;
		content: '\e90e';
		display: inline-block;
		color: $primary;
		font-size: 6px;
		padding-left: 0.3rem;
		padding-right: 0.3rem;
		text-rendering: auto;
		position: relative;
		top: -1px;
	}

	& .ng-dropdown-panel {
		border-radius: 0;
		border: none;
		margin-top: 0;
		// dropdown box shadow
		-moz-box-shadow: 0 2px 3px $shadow;
		-webkit-box-shadow: 0 2px 3px $shadow;
		box-shadow: 0px 2px 4px $shadow;

		.ng-option-selected {
			background-color: unset;

			.ng-option-label {
				font-weight: unset !important;
			}

			&::after {
				font: normal normal normal 14px/1 'admin-icons', sans-serif;
				content: '\e906';
				color: $ng-select-border-color;
				position: absolute;
				right: 7px;
				padding: 6px;
				font-size: 8px;
			}
		}
		.ng-option-disabled {
			color: $ng-select-placeholder-color;
		}
		.ng-option-marked {
			background-color: $dropdown-hover-bg !important;
		}
	}

	&.ng-select-opened > .ng-select-container > span.ng-arrow-wrapper {
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		transform: rotate(180deg);
	}

	&.ng-select-disabled {
		& span.ng-arrow-wrapper:before {
			display: none;
		}
		.ng-select-container {
			border: unset;
			background-color: $disabled-input-bg;
			.ng-value-container {
				.ng-input > input {
					background-color: unset !important;
				}
			}
			&:hover {
				border: unset;
				box-shadow: unset;
			}
		}
	}
}

.pill-tag {
	font-size: 1.3rem;
	background-color: $disabled-input-bg;
	padding: 0 10px;
	text-align: center;
	border-radius: 20px;
	min-width: 35px;
}

.normal-header {
	h1 {
		font-weight: 600;
	}
}

.table th,
.table td {
	border-color: $td-border-color;
}

.asterisk {
	color: $required-symbol;
}

.primary {
	color: $primary;
}

.input-group-text {
	padding: 1.1rem 1.6rem;
	font-size: 1.3rem;
	line-height: 1.2;
}

.verified {
	display: flex;
	font-size: 1.3rem;
	line-height: 1.4rem;
	&.order{
		line-height: 1.8rem;
	}
	.icon {
		color: $alert-success-border;
		font-size: 1.4rem;
		margin-right: 5px;
		&.order {
			font-size: 1.8rem
		}
	}
}

.warning-msg {
	margin-top: 1.5rem;
	display: flex;
	font-size: 1.2rem;
	line-height: 2rem;
	&.order {
		line-height: 1.8rem;
	}
	.material-icons-outlined {
		color: #E07800;
		font-size: 1.8rem;
		margin-right: 5px;
	}
	&.index{
		margin-top: 0;
		line-height: 1.4rem;
		.material-icons-outlined {
			font-size: 1.4rem;
		}
	}
}

.multi-input {
	.nav-tabs {
		border-bottom: 1px solid $tabs_border;
		margin-bottom: 1.6rem;
		.nav-link {
			padding: 1.4rem 2.4rem;
			color: $primary;
			border-top-left-radius: 0.2rem;
			border-top-right-radius: 0.2rem;
			.dot {
				display: inline-block;
				vertical-align: middle;
				margin-left: 0.6rem;
				-webkit-border-radius: 0.2rem;
				-moz-border-radius: 0.2rem;
				border-radius: 0.2rem;
				width: 4px;
				height: 4px;
				background-color: $multi-lang-input-dot;
			}
		}
		.nav-link.active {
			color: $input-text-color;
			border-color: $tabs_border;
			border-bottom: 1px solid $pure-white;
		}
	}
}

#suggested-radio {
	width: 100%;
	.unverified {
		border: 0.1rem solid #a7000d;
		background-color: #fff5f5;
	}
	.strike {
		text-decoration: underline;
	}
	label {
		position: absolute;
		right: 0;
		top: 0;
		left: 0;
		margin: 0;
		z-index: 3;
		bottom: 0;
		padding: 0;
	}
	div.label {
		border: 1px solid $light-hr;
		width: 100%;
		margin-top: 1rem;
		padding: 2rem;
		display: block;
		&.selected {
			border: 1px solid $primary;
		}
		:first-child {
			font-weight: 600;
		}
		> span {
			display: block;
			> span {
				font-weight: normal !important;
			}
		}
		.check {
			top: 2rem;
			right: 2rem !important;
			left: initial;
		}
	}
	.verified {
		//position: absolute;
		top: 1rem;
		right: 1rem;
	}
	.edit-radio {
		background-color: transparent;
		border: none;
		position: absolute;
		right: 1rem;
		z-index: 4;
		top: 1rem;
		color: $primary;
	}
}
#address-not-found-container{
	display: flex;
	align-items: center;
	flex-direction: column;
}
.info-box {
	margin-top: 2rem;
	padding: 1.5rem;
	background-color: $info-modal-bg;
	border: 1px solid $info-modal-border-color;
	> span {
		font-size: 3.6rem;
		margin-right: 1rem;
		color: $info-modal-color;
	}
}

@media (max-width: 575.98px) {
	.header {
		position: static;
		padding: 0;
	}
}

.list-style-disc {
	list-style: disc;
	padding-left: 1.5rem;
}
//EDITOR
.angular-editor-toolbar{
	font: unset !important;
	font-size: 1.4rem !important;
	.angular-editor-toolbar-set .angular-editor-button, .ae-picker-label{
		min-width: 3.1rem !important;
		&:focus{
			outline: 5px auto -webkit-focus-ring-color !important;
		}
	}
	.ae-picker-item.focused,.ae-picker-label,.ae-picker-options button{
		&:focus{
			outline: 5px auto -webkit-focus-ring-color !important;
		}
	}
}
.angular-editor-textarea{
	margin-top: 0 !important;
}
.available-placeholders-wrapper{
	background-color: #f9f9f9;
	padding: 5px;
	border: 1px solid $input-border-color;
	border-top:none;

	button{
		color: #037fb0;
		border: 1px solid #037fb0;
		background-color: #fff;
		padding: 2px 10px;
		font-size: 1.3rem;
	}
}
angular-editor.is-invalid.ng-touched .angular-editor-textarea{
	border: 1px solid $error-primary !important;
	background-color: $error-background !important;
}
.loading-text:after {
	content: ' .';
	animation: dots 1s steps(5, end) infinite;}

.rules-group-header {
	.group-conditions {
		.btn:has(input[name$='_cond']:focus){
			outline: 5px auto -webkit-focus-ring-color;
		}
	}
}

.query-builder .rules-group-container .rule-container{
	overflow: auto;
}
.query-builder-wrapper .btn-success{
	background-color: #218739;
	border-color: #218739;
}

#delete-img-success {

	border-radius: 0;
	padding: 2.5rem;
	font-size: 1.6rem;
	background-color: #fff;
	border-color: $alert-success-border;
	color: $text-color;
	.icon{
		color: $alert-success-border;
	}

	button.close{
		width: 36px;
		height: 36px;
		font-size: 22px;
		padding: 0 !important;
	}
}

@media (max-width: 575.98px) {
	.submit-form-btn{
		custom-button{
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: 50%;
			margin-bottom: 2rem;
			&:first-child {
				padding-right: 0.5rem !important;
			}
			&:last-child{
				padding-left: 0.5rem !important;
			}
			a,button{
				width: 100%;
			}
		}
	}
}


@keyframes dots {
	0%, 20% {
		color: rgba(0,0,0,0);
		text-shadow:
						.25em 0 0 rgba(0,0,0,0),
						.5em 0 0 rgba(0,0,0,0);}
	40% {
		color: black;
		text-shadow:
						.25em 0 0 rgba(0,0,0,0),
						.5em 0 0 rgba(0,0,0,0);}
	60% {
		text-shadow:
						.25em 0 0 black,
						.5em 0 0 rgba(0,0,0,0);}
	80%, 100% {
		text-shadow:
						.25em 0 0 black,
						.5em 0 0 black;}}
